<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#aacf17" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useCalculatorApi } from "~/api/useCalculatorApi";

const { fetchChat } = useCalculatorApi();

const { data } = await fetchChat();

const jivoScript = computed(() => {
  if (!data.value) return null;
  return data.value;
});

useHead({
  script: [jivoScript.value],
});

const colorMode = useColorMode();

onMounted(() => {
  if (colorMode.value === "light") {
    colorMode.preference = "dark";
    colorMode.value = "dark";
  }
});
</script>
