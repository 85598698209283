import { useCalculatorApi } from "~/api/useCalculatorApi";

export function useSettings() {
  const { fetchSetting } = useCalculatorApi();

  const settings = useState<any>("settings", () => null);

  const getSettings = async () => {
    const { data, error } = await fetchSetting();
    if (error.value) {
      return;
    }
    settings.value = data.value;
  };

  const contestStatus = computed(() => settings.value?.contest);
  const captchaLogin = computed(() => settings.value?.captchaLogin);

  return {
    contestStatus,
    getSettings,
    captchaLogin,
  };
}
